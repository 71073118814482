import React from 'react';
import { graphql } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    layout,
    hero,
    heroContent,
    heroContentBox,
    breadcrumbsBox,
    markdown,
    stepItem,
    stepNumber,
    stepTitle,
    stepContent,
    whyContent,
    technologyTitle,
    technologyText,
    introContent,
    challengesContent,
} from './city.module.scss';
import { ICity } from '../models/city.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IClientOpinion } from '../models/client-opinion.model';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Section from '../components/hoc/section';
import Markdown from '../components/hoc/markdown';
import SectionClients from '../components/organisms/section-clients';
import SectionContactCta from '../components/organisms/section-contact-cta';
import SectionEcommerceCases from '../components/organisms/section-ecommerce-cases';
import SectionFaqCta from '../components/organisms/section-faq-cta';
import SectionContactForm from '../components/organisms/section-contact-form';
import SectionCities from '../components/organisms/section-cities';

interface ICityPageProps {
    readonly data: {
        city: ICity;
        heroImg: ImageDataLike;
        introImg: ImageDataLike;
        whyImg: ImageDataLike;
        challengesImg: ImageDataLike;
        citiesImg: ImageDataLike;
        allClientOpinion: IQueryAllResult<IClientOpinion>;
    };
}

const CityPage: React.FC<ICityPageProps> = ({ data }) => {
    const { city, heroImg, introImg, whyImg, challengesImg, allClientOpinion } = data;
    const { breadcrumbs } = usePageContext();
    const { t } = useT();

    const clientOpinions = getNodes(allClientOpinion);

    return (
        <MainLayout className={layout} showCitiesSection={true}>
            <Section
                className={hero}
                theme="dark"
                contentDisplay="left"
                contentClassName={heroContent}
                contentBoxClassName={heroContentBox}
                titleProps={{ children: city.title, Tag: 'h1', size: 'large', weight: 'bold' }}
                circleDisplay="none"
                height="auto"
                image={heroImg && getImage(heroImg)}
                breadcrumbsProps={{ breadcrumbs, className: breadcrumbsBox }}
            />
            <Section
                theme="light"
                contentDisplay="right"
                contentClassName={introContent}
                titleProps={{
                    children: city.intro.title,
                    Tag: 'h2',
                    weight: 'bold',
                }}
                circleDisplay="none"
                height="auto"
                image={introImg && getImage(introImg)}
            >
                <Markdown className={markdown}>{city.intro.content}</Markdown>
            </Section>
            <Section
                theme="dark"
                contentDisplay="center"
                titleProps={{
                    children: city.steps.title,
                    Tag: 'h2',
                    weight: 'bold',
                    size: 'large',
                }}
                circleDisplay="none"
                height="auto"
            >
                <ul>
                    {city.steps.items.map((step, index) => {
                        return (
                            <li key={`step-${index}`} className={stepItem}>
                                <p className={stepNumber}>{index + 1}</p>
                                <h3 className={stepTitle}>{step.title}</h3>
                                <Markdown className={`${markdown} ${stepContent}`}>
                                    {step.content}
                                </Markdown>
                            </li>
                        );
                    })}
                </ul>
            </Section>
            <Section
                theme="light"
                contentDisplay="paralel"
                contentClassName={whyContent}
                titleProps={{
                    children: city.why.title,
                    Tag: 'h2',
                    weight: 'bold',
                }}
                circleDisplay="none"
                height="auto"
                image={whyImg && getImage(whyImg)}
            >
                <Markdown className={markdown}>{city.why.content}</Markdown>
            </Section>
            <Section
                theme="dark"
                contentDisplay="center"
                titleProps={{
                    children: city.technology.title,
                    Tag: 'h2',
                    weight: 'bold',
                    className: technologyTitle,
                }}
                circleDisplay="none"
                height="auto"
            >
                <Markdown className={`${markdown} ${technologyText}`}>
                    {city.technology.content}
                </Markdown>
            </Section>
            <SectionClients
                clientOpinions={clientOpinions}
                titleProps={{
                    Tag: 'h2',
                    children: t('city.clients.title'),
                    subtitle: t('city.clients.subtitle'),
                }}
            />
            <SectionContactCta hasPadding={true} />
            <Section
                theme="dark"
                contentDisplay="paralel"
                titleProps={{
                    children: city.seo.title,
                    Tag: 'h2',
                    weight: 'bold',
                }}
                circleDisplay="none"
                height="auto"
            >
                <Markdown className={markdown}>{city.seo.content}</Markdown>
            </Section>
            <SectionEcommerceCases />
            <SectionFaqCta />
            <Section
                theme="light"
                contentDisplay="left"
                contentClassName={challengesContent}
                titleProps={{
                    children: city.challenges.title,
                    Tag: 'h2',
                    weight: 'bold',
                }}
                circleDisplay="none"
                height="auto"
                image={challengesImg && getImage(challengesImg)}
            >
                <Markdown className={markdown}>{city.challenges.content}</Markdown>
            </Section>
            <SectionContactForm
                titleProps={{ children: t('city.contact.title'), weight: 'bold' }}
                description={t('city.contact.text')}
                buttonProps={{ color: 'blue', kind: 'normalDark' }}
            />
        </MainLayout>
    );
};

export const query = graphql`
    query CityPage($locale: String!, $cityId: Int!) {
        city(locale: { eq: $locale }, cityId: { eq: $cityId }) {
            ...cityFields
        }
        heroImg: file(relativePath: { eq: "city-hero.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 95)
            }
        }
        introImg: file(relativePath: { eq: "city-intro.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 95)
            }
        }
        whyImg: file(relativePath: { eq: "city-why.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 95)
            }
        }
        challengesImg: file(relativePath: { eq: "city-challenges.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 95)
            }
        }
        allClientOpinion(
            filter: { locale: { eq: $locale }, isGlobal: { eq: true } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...clientOpinionFields
                }
            }
        }
    }
`;

export default CityPage;
